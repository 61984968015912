import { connect } from 'react-redux';
import Form from './Form';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
