import React, { Component } from 'react';
import classNames from 'classnames';
import { TweenMax, TimelineMax, Power2 } from 'gsap';

import HoverWobble from 'components/HoverWobble';
import Hamburger from 'components/Hamburger';
import Link from 'components/Link';

import { Routes } from 'App/Routes';

import { EmailAddress } from 'constants.js';

import './Nav.scss';

class Nav extends Component {

  constructor(props) {
    super();

    this.state = {
      toggled: false,
    }
    this.$navItems = [];
  }

  componentDidMount = () => {
    const navItems = this.$navItems;

    TweenMax.set(this.$ghIcon, { autoAlpha: 0, x: -15});
    TweenMax.set(this.$emailIcon, { autoAlpha: 0, x: -15});
    TweenMax.set(this.$lightIcon, { autoAlpha: 0, x: 15});

    for (let [i, item] of navItems.entries()) {
      TweenMax.set(item, { autoAlpha: 0, y: -5, });
      TweenMax.to(item, 0.3, {
        autoAlpha: 1, y: 0,
        ease: Power2.easeIn,
        delay: i * 0.2,
        onComplete: () => i === navItems.length - 1 && this.animInIcons(),
      })
    }
  }

  animInIcons = () => {
    const tl = new TimelineMax();

    tl.to(this.$ghIcon, 0.3, { autoAlpha: 1, x: 0, ease: Power2.easeIn, })
      .to(this.$emailIcon, 0.3, { autoAlpha: 1, x: 0, ease: Power2.easeIn, })
      .to(this.$lightIcon, 0.3, { autoAlpha: 1, x: 0, ease: Power2.easeIn, })
  }

  toggleMenu = () => {
    this.setState({ toggled: !this.state.toggled })
  }

  render = () => {
    const { route, switchTheme, history } = this.props;
    const classes = classNames({
      'Nav': true,
      'Toggled': this.state.toggled,
    })

    return (
      <nav className={ classes } ref={el => this.$node = el}>
        <Hamburger toggled={ this.state.toggled} onClick={ this.toggleMenu } />
        <ul>
          {
            Routes.filter(route => { return !route.isSubRoute }).map((r, key) => (
              <li
                key={ key }
                ref={ el => this.$navItems[key] = el }
                className={ route === r.path ? 'current' : ''}
                onClick={ this.toggleMenu }
              >
                <Link history={ history } to={ r.path } disabled={ route === r.path }>
                  <HoverWobble disabled={ route === r.path }>
                    { r.name }
                  </HoverWobble>
                </Link>
              </li>
            ))
          }
        </ul>

        <div className="social-icons">
          <a href="https://github.com/ECDStudio" target="_blank" rel="noopener noreferrer" ref={ el => this.$ghIcon = el }>
            <HoverWobble horizontal>
              <i className="fa fa-github-alt" aria-hidden="true" /><span>Github</span>
            </HoverWobble>
          </a>
          <a href={ `mailto:${ EmailAddress }` } target="_blank" rel="noopener noreferrer" ref={ el => this.$emailIcon = el }>
            <HoverWobble horizontal>
              <i className="fa fa-envelope" aria-hidden="true" /><span>Email</span>
            </HoverWobble>
          </a>
        </div>

        <div className="lightbulb">
          <button onClick={ switchTheme } ref={ el => this.$lightIcon = el }>
            <HoverWobble horizontal>
              <i className="fa fa-lightbulb-o" />
            </HoverWobble>
          </button>
        </div>
      </nav>
    );
  }
}

export default Nav;
