import React, { Component } from 'react';
import classNames from 'classnames';
import isMobile from 'ismobilejs';

import './Hamburger.scss';

class Hamburger extends Component {
  constructor(props) {
      super(props);

      this.state = {
        hover: false,
        isMobile: isMobile().any,
      };
  }

  render = () => {
    const { toggled, onClick } = this.props;
    const { hover, isMobile } = this.state;

    const classes = classNames({
      'Hamburger': true,
      'toggled': toggled,
      'hover': hover && !isMobile,
    })

    return (
      <button
        className={ classes }
        ref={ el => this.$node = el }
        onClick={ onClick }
        onMouseEnter={ () => this.setState({ hover: true }) }
        onMouseLeave={ () => this.setState({ hover: false }) }
      />
    )
  }
}

export default Hamburger;
