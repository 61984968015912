// Sections.
import Home from 'sections/Home';
import Works from 'sections/Works';
import About from 'sections/About';
import Contact from 'sections/Contact';
import Project from 'sections/Project';

export const Routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    exact: true,
  },
  {
    path: '/about',
    component: About,
    name: 'About',
    exact: true,
  },
  {
    path: '/works',
    component: Works,
    name: 'Works',
    exact: true,
  },
  {
    path: '/contact',
    component: Contact,
    name: 'Contact',
    exact: true,
  },
  {
    path: '/project/:project',
    component: Project,
    name: 'Project',
    exact: false,
    isSubRoute: true,
  },
]