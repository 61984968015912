import React, { Component } from 'react';
import { TimelineMax, Power0, Power2 } from 'gsap';

import TickManager from 'managers/TickManager';

import './Home.scss';

const EASE_FACTOR = 0.05;

class Home extends Component {

  constructor(props) {
      super(props);

      this.state = {
        
      };

      this.tickId = null;

      this.targetRotate = { x: 0, y: 0 };
      this.currentRotate = { x: 0, y: 0 };
      this.cursorOffset = { x: 0, y: 0 };

      this.bouncing = false;
      this.spanHover =false;
  }

  componentDidMount = () => {
    this.props.updateRoute('/');

    this.tickId = TickManager.on( this.onTick );
    window.addEventListener( 'mousemove', this.handleMouseMove );

    this.animateSpan(1.1);
  }

  componentWillUnmount = () => {
    TickManager.off( this.tickId );
    window.removeEventListener( 'mousemove', this.handleMouseMove );
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.transitionActive && this.props.transitionActive) this.animateOut();
  }

  onTick = () => {
    this.targetRotate = {
      x: this.cursorOffset.y * 30,
      y: - this.cursorOffset.x * 30,
    }

    this.currentRotate.x += (this.targetRotate.x - this.currentRotate.x) * EASE_FACTOR;
    this.currentRotate.y += (this.targetRotate.y - this.currentRotate.y) * EASE_FACTOR;

    const transform = `rotateX(${ this.currentRotate.x }deg) rotateY(${ this.currentRotate.y }deg)`;

    this.$logo.style.transform = transform;
  }

  handleMouseMove = (e) => {
    const { width, height } = this.props.viewport;

    const prevCursorOffset = { ...this.cursorOffset };

    this.cursorOffset = {
      x: (e.clientX / width) - 0.5,
      y: (e.clientY / height) - 0.5,
    };

    if (prevCursorOffset !== this.cursorOffset) this.forceUpdate();
  }

  handleSpanHover = () => {
    this.spanHover = true;
    if (this.bouncing) return;
    this.animateSpan();
  }

  handleSpanOut = () => {
    this.spanHover = false;
  }

  animateSpan = (delay = 0) => {
    this.bouncing = true;

    const tl = new TimelineMax({
      delay,
      onComplete: () => {
        this.bouncing = false;
        if (this.spanHover) this.animateSpan(1);
      }
    });
    tl.from(this.span, 0, { rotation: -15 })
      .to(this.span, 0.3, { rotation: 0, ease: Power2.easeIn, })
      .to(this.span, 0.25, { rotation: -30, ease: Power0.easeNone, })
      .to(this.span, 0.2, { rotation: -10, ease: Power0.easeNone, })
      .to(this.span, 0.175, { rotation: -20, ease: Power0.easeNone, })
      .to(this.span, 0.15, { rotation: -12, ease: Power0.easeNone, })
      .to(this.span, 0.125, { rotation: -18, ease: Power0.easeNone, })
      .to(this.span, 0.1, { rotation: -15, ease: Power2.easeOut, })
  }

  animateOut = () => {
    const tl = new TimelineMax({
      onComplete: () => this.props.activateTransition(false)
    });
    tl.to(this.$logo, 0.3, { y: 30, autoAlpha: 0, ease: Power2.easeOut, })
  }

  render = () => {
    return (
      <section className="Home" ref={el => this.$node = el}>
        <div className="logo-wrapper" onMouseEnter={ this.handleSpanHover } onMouseLeave={ this.handleSpanOut }>
          <div className="logo" ref={ el => this.$logo = el }>
            <b>E</b>c<b>D</b>ev
            <span ref={ el => this.span = el }>Studio</span>
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
