import React, { Component } from 'react';
import classNames from 'classnames';
import { TweenMax, TimelineMax, Power1, Power2 } from 'gsap';

import Form from './elements/Form';

import { EmailAddress } from 'constants.js';

import './Contact.scss';

class Contact extends Component {
  constructor(props) {
      super(props);

      this.state = {
        formAnimateIn: false
      };
  }

  componentDidMount = () => {
    this.props.updateRoute('/contact');
    
    TweenMax.set(this.$heading, { autoAlpha: 0, x: -40});
    TweenMax.set(this.$content, { autoAlpha: 0, y: 30});

    this.animateIn();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.transitionActive && this.props.transitionActive) {
      if (this.state.formAnimateIn) this.animateOutForm();
      else this.animateOut();
    }
  }

  animateIn = () => {
    const tl = new TimelineMax({
      onComplete: () => this.setState({ formAnimateIn: true })
    });

    tl.to(this.$heading, 0.5, { autoAlpha: 1, x: 0, ease: Power2.easeIn, })
      .to(this.$content, 0.6, { autoAlpha: 1, y: 0, ease: Power1.easeOut, })
  }

  animateOutForm = () => {
    this.setState({ formAnimateIn: false })
  }

  animateOut = () => {
    const tl = new TimelineMax({
      onComplete: () => this.props.activateTransition(false),
    });

    tl.to(this.$content, 0.3, { autoAlpha: 0, y: 30, ease: Power1.easeIn, })
      .to(this.$heading, 0.25, { autoAlpha: 0, x: -40, ease: Power2.easeOut, })
  }


  render = () => {
    const classes = classNames({
      'Contact': true
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el }>
        <div className="container">
          <div className="row space50">
            <div className="md-1-2 xl-1-3">
              <h1 ref={ el => this.$heading = el }>Please Say Hi!</h1>
              <div ref={ el => this.$content = el }>
                <p>
                  If you would like to connect, please fill out the contact form or send me an email at <a href={ `mailto:${ EmailAddress }` }>{ EmailAddress }</a>.
                </p>
                <p>
                  I look forward to hearing from you!
                </p>
              </div>
            </div>
            <div className="md-1-2 xl-2-3">
              <Form animateIn={ this.state.formAnimateIn } handleAnimateOut={ this.animateOut } />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact;
