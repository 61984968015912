import { connect } from 'react-redux';
import Link from './Link';

import { activateTransition } from 'redux/actions';

const mapStateToProps = state => ({
  transitionActive: state.app.transitionActive,
});

const mapDispatchToProps = {
  activateTransition,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Link);
