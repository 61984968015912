import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import preloader from 'preloader';

import Loader from 'components/Loader';

import TickManager from 'managers/TickManager';

import { API } from 'constants.js';

import './Preloader.scss';

const MAX_TICK = 100;

class Preloader extends Component {
  constructor(props) {
      super(props);

      this.state = {
        tick: 0,
        progress: 0,
      };

      this.tickId = null;

      this.interval = null;
      this.loader = preloader({ xhrImages: false });
  }

  componentDidMount = () => {
    this.tickId = TickManager.on( this.onTick );
    this.fetchData();
  }

  componentWillUnmount = () => {
      TickManager.off( this.tickId );
      this.loader = null;
  }

  /**
   * Tick handler which will increase state.tick by one, until it reaches the
   * state.progress or hits the MAX_TICK.
   *
   * @memberof Preloader
   */
  onTick = () => {
      const { tick, progress } = this.state;

      const incrementPerTick = 1;
      const nextTick = tick < progress ? Math.min(tick + incrementPerTick, MAX_TICK) : tick;

      this.setState({ tick: nextTick }, () => {
          if (nextTick >= MAX_TICK) {
            TickManager.off( this.tickId );
            this.props.completePreload();
          }
      });
  }

  fetchData = async () => {
    const promises = [
      axios.get(`${API}/about/`),
      axios.get(`${API}/skills/`),
      axios.get(`${API}/works/`),
    ];

    try {
      await Promise.all(promises).then(this.handleApiResponse)
    } catch(err) {
      console.log(err)
    }
  }

  handleApiResponse = (res) => {
    this.setState({ progress: 50 }, () => {
      const data = {
        about: res[0].data,
        skills: res[1].data,
        works: res[2].data,
      }
      this.props.updateData(data);
      const assetsToLoad = [];
      data.works.forEach(work => assetsToLoad.push(work.image));
      this.loadImages(assetsToLoad);
    })
  }

  loadImages = (assets) => {
      if (assets.length === 0) this.setState({ progress: 100 });
      else {
        assets.forEach(asset => this.loader.addImage(asset, {
            onComplete: () => this.setState({ progress: this.state.progress + 50 / assets.length }),
        }));
  
        this.loader.load();
      }
  }

  render = () => {
    const { preloadCompleted } = this.props;
    const classes = classNames({
      'Preloader': true,
      'completed': preloadCompleted,
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el }>
        <div className="load-bar" style={ { transform: `scaleX(${ this.state.tick / 100 })` } } />
        <Loader hidden={ preloadCompleted } />
      </div>
    )
  }
}

export default Preloader;
