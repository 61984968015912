import React, { Component } from 'react';
import classNames from 'classnames';

import './Content.scss';

class Content extends Component {
  constructor(props) {
      super(props);

      this.state = {
        
      };
  }

  render = () => {
    const { project } = this.props;
    const classes = classNames({
      'Content': true,
      'container': true
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el }>
        <div className="row reversed">
          <div className="md-1-2">
            <p dangerouslySetInnerHTML={{ __html: project.description }} />
            <p>
              <a href={ project.link } target="_blank" rel="noopener noreferrer">
                <i className="fa fa-external-link" /> Visit { project.type }
              </a>
              {
                project.github &&
                <a href={ project.github } target="_blank" rel="noopener noreferrer" style={{ marginLeft: '25px' }}>
                  <i className="fa fa-external-link" /> View code on GitHub
                </a>
              }
            </p>
          </div>
          <div className="md-1-4">
            {
              project.agency &&
              <div className="detail">
                <h2>Agency</h2>
                <span>{ project.agency }</span>
              </div>
            }
            <div className="detail">
              <h2>Role</h2>
              <ul>
                { project.roles.map((role, key) => (
                  <li key={ key }><span>{ role }</span></li>
                )) }
              </ul>
            </div>
            <div className="detail">
              <h2>Tools</h2>
              <ul>
                { project.tools.map((tool, key) => (
                  <li key={ key }><span>{ tool }</span></li>
                )) }
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Content;
