import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { TweenMax, TimelineMax, Power1 } from 'gsap';

import Loader from 'components/Loader';

import { API } from 'constants.js';

import './Form.scss';

const VALIDATE_EMAIL = (email) => {
  // https://www.w3resource.com/javascript/form/email-validation.php
  return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
}

const FIELDS = [
  { name: 'name' },
  { name: 'email' },
  { name: 'phone', optional: true },
  { name: 'subject' },
  { name: 'message', textarea: true },
]

class Form extends Component {
  constructor(props) {
      super(props);

      this.state = {
        currentFocused: -1,
        data: {},
        submitting: false,
        valid: false,
        success: false,
        error: false,
      };

      this.$fields = [];
      FIELDS.forEach(() => this.$fields.push({}));
  }

  componentDidMount = () => {
    this.$fields.forEach(field => {
      TweenMax.set(field.field, { x: '-101%' });
      TweenMax.set(field.heading, { autoAlpha: 0, y: 20 });
    });
    TweenMax.set(this.$button, { autoAlpha: 0, y: 20 });
  }

  componentDidUpdate = (prevProps) => {
    const { animateIn } = this.props;
    if (!prevProps.animateIn && animateIn) this.animateIn();
    if (prevProps.animateIn && !animateIn) this.animateOut();
  }

  animateIn = () => {
    const tl = new TimelineMax();

    this.$fields.forEach(field => {
      tl.to(field.field, 0.1, { x: '0%', ease: Power1.easeIn, })
        .to(field.heading, 0.15, { autoAlpha: 0.75, y: 0, ease: Power1.easeOut, })
    });

    tl.to(this.$button, 0.25, { autoAlpha: 1, y: 0, ease: Power1.easeOut, })
  }

  animateOut = () => {
    const tl = new TimelineMax();

    tl.to(this.$button, 0.05, { autoAlpha: 0, y: 20, ease: Power1.easeIn, })

    for (let i = this.$fields.length - 1; i >= 0; i --) {
      tl.to(this.$fields[i].heading, 0.05, { autoAlpha: 0, y: 20, ease: Power1.easeIn, })
        .to(this.$fields[i].field, 0.025, { x: '-101%', ease: Power1.easeOut,
          onComplete: i === 0 && this.props.handleAnimateOut })
    }
  }

  handleChange = e => {
    const { data } = this.state;
    const currentField = e.target;

    this.setState({
      data: {
        ...data,
        [currentField.name]: currentField.value,
      },
      error: false,
    }, this.validate)
  }

  validate = () => {
    const { data } = this.state;
    let valid = true;

    if (!data.name || data.name === '' || data.name.length < 2 ||
      !data.email || data.email === '' || !VALIDATE_EMAIL(data.email) ||
      !data.subject || data.subject === '' || data.subject.length < 2 ||
      !data.message || data.message === '' ||  data.message.length < 5
    ) valid = false;

    this.setState({ valid });
  }

  handleSubmit = e => {
    e.preventDefault();

    const { data, submitting } = this.state;
    if (submitting) return;

    this.setState({ submitting: true }, () => {
      this.postToApi(data);
    })
  }

  postToApi = async data => {
    console.log(data);

    try {
      await axios.post(`${ API }/contact/`, data)
        .then(res => this.setState({ submitting: false, success: true }));
    } catch(err) {
      console.log(err)
      this.setState({ submitting: false, error: true })
    }
  }


  render = () => {
    const { currentFocused, submitting, valid, success, error } = this.state;

    const classes = classNames({
      'Form': true,
      'disabled': submitting || success,
    })
  
    return (
      <form className={ classes } ref={ el => this.$node = el }>
        {
          FIELDS.map((field, key) => (
            <div ref={ el => this.$fields[key].field = el } key={ key } className={ `field ${ currentFocused === key && ' current' }` }>
              <label htmlFor={ field.name } ref={ el => this.$fields[key].heading = el }>
                { field.name }{ field.optional && ' (Optional)' }
              </label>
              {
                field.textarea ?
                <textarea
                  id={ field.name }
                  name={ field.name }
                  onChange={ this.handleChange }
                  onFocus={ () => this.setState({ currentFocused: key })}
                  onBlur={ () => this.setState({ currentFocused: -1 })}></textarea> :
                <input
                  id={ field.name }
                  name={ field.name }
                  onChange={ this.handleChange }
                  onFocus={ () => this.setState({ currentFocused: key })}
                  onBlur={ () => this.setState({ currentFocused: -1 })}
                />
              }
            </div>
          ))
        }
        <div>
          {
            success &&
            <p>Thank you for contacting! I will get back to you as soon as possible.</p>
          }
          {
            error &&
            <p>Oops! There is an error in your submission. Please review the form and try again.</p>
          }
        </div>
        <div style={{ textAlign: 'right' }} ref={ el => this.$button = el }>
          <button onClick={ this.handleSubmit } disabled={ !valid }>
            - { success ? 'submitted' : <span>Submit</span> } -
          </button>
        </div>

        <Loader hidden={ !submitting } />
      </form>
    )
  }
}

export default Form;
