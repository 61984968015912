import { connect } from 'react-redux';
import Slide from './Slide';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Slide);
