import { connect } from 'react-redux';
import Nav from './Nav';

const mapStateToProps = state => ({
  route: state.app.route,
});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav);
