import React, { Component } from 'react';
import classNames from 'classnames';

import './Slide.scss';

class Slide extends Component {
  constructor(props) {
      super(props);

      this.state = {
        
      };
  }

  componentDidMount = () => {
    this.props.initThumbnailRef(this.$thumbnail);
  }

  render = () => {
    const { data, current, onClick, setCurrent, transitioning, transitioningToProject } = this.props;
    const classes = classNames({
      'Slide': true,
      'transitioning': transitioning,
      'to-project': transitioningToProject,
      'current': current,
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el } onClick={ onClick }>
        <div className="work">
          <div className="thumbnail" ref={ el => this.$thumbnail = el } style={{ backgroundImage: `url('${ data.image }')` }} />
          <div className="set-current-layer" onClick={ setCurrent } />
        </div>
      </div>
    )
  }
}

export default Slide;
