import { connect } from 'react-redux';
import Content from './Content';

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);
