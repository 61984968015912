export const completePreload = () => ({
  type: 'COMPLETE_PRELOAD',
  preloadCompleted: true,
});

export const updateData = data => ({
  type: 'UPDATE_DATA',
  data,
});

export const updateViewport = (width, height) => ({
  type: 'UPDATE_VIEWPORT',
  viewport: { width, height },
});

export const updateRoute = route => ({
  type: 'UPDATE_ROUTE',
  route,
});

export const activateTransition = (state = true) => ({
  type: 'ACTIVATE_TRANSITION',
  state,
});

export const updateCurrentSlide = (slide = 0) => ({
  type: 'UPDATE_CURRENT_SLIDE',
  slide,
});