import React, { Component } from 'react';
import classNames from 'classnames';

import './Link.scss';

class Link extends Component {
  constructor(props) {
      super(props);

      this.state = {
        active: false,
      };
  }

  componentDidUpdate = (prevProps) => {
    const { transitionActive, history, to } = this.props;
    if (!this.state.active) return;

    if (prevProps.transitionActive && !transitionActive) {
      this.setState({ active: false }, () => history.push(to));
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    const { disabled, transitionActive, activateTransition } = this.props;

    if (disabled || transitionActive) return;
    this.setState({ active: true }, activateTransition);
  }

  render = () => {
    const classes = classNames({
      'Link': true
    })
  
    return (
      <a className={ classes } ref={ el => this.$node = el } onClick={ this.handleClick } href={ this.props.to }>
        { this.props.children }
      </a>
    )
  }
}

export default Link;
