import React, { Component } from 'react';
import classNames from 'classnames';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

// Routes.
import { Routes } from './Routes.js';

import Preloader from 'components/Preloader';
import Nav from 'components/Nav';

import './App.scss';

const history = createHistory();

class App extends Component {

  constructor() {
    super();

    this.state = {
      darkMode: localStorage.getItem('darkMode'),
    }
  }

  componentDidMount = () => {
    this.props.updateViewport(window.innerWidth, window.innerHeight);
    window.addEventListener( 'resize', this.handleResize );

    history.push({ state: { prevPath: null } });
  }

  componentWillUnmount = () => {
    window.removeEventListener( 'resize', this.handleResize );
  }

  handleResize = () => {
    this.props.updateViewport(window.innerWidth, window.innerHeight);
  }

  switchTheme = () => {
    this.setState({ darkMode: !this.state.darkMode }, () => {
      if (this.state.darkMode) localStorage.setItem('darkMode', true);
      else localStorage.clear();
    })
  }

  render = () => {
    const { preloadCompleted } = this.props;
    const classes = classNames({
      'App': true,
      'DarkMode': this.state.darkMode,
    })

    return (
      <Router history={ history }>
        <div className={ classes }>
          <Preloader />
          {
            preloadCompleted &&
            <Nav switchTheme={ this.switchTheme } history={ history } />
          }
          <div className="pages">
            {
              preloadCompleted &&
              Routes.map((route, key) => (
                <Route
                  exact={ route.exact }
                  path={ route.path }
                  component={ route.component }
                  key={ key }
                />
              ))
            }
          </div>
        </div>
      </Router>
    )
  }
}

export default App;
