import React, { Component } from 'react';
import classNames from 'classnames';
import { TimelineMax, Power0, Power2 } from 'gsap';

import './HoverWobble.scss';

class HoverWobble extends Component {
  constructor(props) {
      super(props);

      this.state = {
        hover: false,
        animating: false,
      };
  }

  componentDidMount = () => {
    this.tl = new TimelineMax();
  }

  componentDidUpdate = (_, prevState) => {
    const { hover, animating } = this.state;
    if (!this.props.disabled && !prevState.hover && hover && !animating) this.wobble();
  }

  wobble = (delay = 0) => {
    const { disabled, horizontal } = this.props;
    const duration = 0.18;
    const translate = (unit) => (
      horizontal ? { x: unit } : { y: unit }
    )

    this.tl.from(this.$node, delay, translate(0));

    if (disabled || !this.state.hover) return;

    this.setState({ animating: true }, () => {
      this.tl.to(this.$node, duration, { ...translate(8), ease: Power2.easeIn, })
        .to(this.$node, duration, { ...translate(-6), ease: Power0.easeNone, })
        .to(this.$node, duration, { ...translate(4), ease: Power0.easeNone, })
        .to(this.$node, duration, { ...translate(-2), ease: Power0.easeNone, })
        .to(this.$node, duration, { ...translate(1), ease: Power0.easeNone, })
        .to(this.$node, duration, { ...translate(0), ease: Power2.easeOut,
          onComplete: () => {
            this.setState({ animating: false }, () => this.wobble(1))
          }
        })
    })
  }

  handleMouseEnter = () => this.setState({ hover: true })

  handleMouseLeave = () => this.setState({ hover: false })

  render = () => {
    const classes = classNames({
      'HoverWobble': true
    })
  
    return (
      <div
        className={ classes }
        ref={ el => this.$node = el }
        onMouseEnter={ this.handleMouseEnter }
        onMouseLeave={ this.handleMouseLeave }
      >
        { this.props.children }
      </div>
    )
  }
}

export default HoverWobble;
