import { connect } from 'react-redux';
import Works from './Works';

import { updateRoute, activateTransition, updateCurrentSlide } from 'redux/actions';

const mapStateToProps = state => ({
  viewport: state.app.viewport,
  data: state.app.data.works,
  transitionActive: state.app.transitionActive,
  currentSlide: state.app.currentSlide,
});

const mapDispatchToProps = {
  updateRoute,
  activateTransition,
  updateCurrentSlide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Works);
