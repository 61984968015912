import { connect } from 'react-redux';
import Project from './Project';

import { updateRoute, activateTransition } from 'redux/actions';

const mapStateToProps = state => ({
  viewport: state.app.viewport,
  data: state.app.data.works,
  transitionActive: state.app.transitionActive,
});

const mapDispatchToProps = {
  updateRoute,
  activateTransition,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Project);
