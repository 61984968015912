import React, { Component } from 'react';
import classNames from 'classnames';
import { TweenMax, TimelineMax, Power1, Power2 } from 'gsap';

import Link from 'components/Link';

import './About.scss';

class About extends Component {
  constructor(props) {
      super(props);

      this.state = {
        
      };

      this.$skills = [];

      props.skills.forEach(skill => {
        const list = [];
        skill.list.forEach(() => list.push({}));
        this.$skills.push({ list });
      });
  }

  componentDidMount = () => {
    this.props.updateRoute('/about');

    TweenMax.set(this.$heading, { autoAlpha: 0, x: -40});
    TweenMax.set(this.$content, { autoAlpha: 0, y: 30});

    this.$skills.forEach(skill => {
      TweenMax.set(skill.heading, { autoAlpha: 0, x: -25});

      skill.list.forEach(item => {
        TweenMax.set(item.wrap, { autoAlpha: 0, y: 10});
      });
    });

    this.animateIn();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.transitionActive && this.props.transitionActive) this.animateOut();
  }

  animateIn = () => {
    const tl = new TimelineMax();

    tl.to(this.$heading, 0.5, { autoAlpha: 1, x: 0, ease: Power2.easeIn, })
      .to(this.$content, 0.6, { autoAlpha: 1, y: 0, ease: Power1.easeOut, })

    this.$skills.forEach(skill => {
      tl.to(skill.heading, 0.1, { autoAlpha: 1, x: 0, ease: Power1.easeIn, })

      skill.list.forEach(item => {
        tl.to(item.wrap, 0.1, {
          autoAlpha: 1,
          y: 0,
          ease: Power1.easeOut,
          onComplete: () => item.gauge && item.gauge.classList.add('animate-in'),
        })
      });
    });
  }

  animateOut = () => {
    const tl = new TimelineMax();

    for (let i = this.$skills.length - 1; i >= 0; i --) {
      for (let j = this.$skills[i].list.length - 1; j >= 0; j --) {
        tl.to(this.$skills[i].list[j].wrap, 0.025, {
          autoAlpha: 0,
          y: 10,
          ease: Power1.easeOut,
        })
      }

      tl.to(this.$skills[i].heading, 0.025, {
        autoAlpha: 0,
        x: -25,
        ease: Power1.easeIn,
      })
    }

    tl.to(this.$content, 0.2, { autoAlpha: 0, y: 30, ease: Power1.easeOut, })
      .to(this.$heading, 0.1, { autoAlpha: 0, x: -40, ease: Power1.easeOut,
        onComplete: () => this.props.activateTransition(false) })
  }

  render = () => {
    const { about, skills, history } = this.props;

    const classes = classNames({
      'About': true
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el }>
        <div className="container">
          <div className="row space50">
            <div className="md-1-2 xl-2-3">
              <h1 ref={ el => this.$heading = el }>Hello!</h1>
              <div ref={ el => this.$content = el }>
                <div dangerouslySetInnerHTML={{ __html: about }} />
                <p>
                  Please feel free to take a look at some of <Link history={ history } to="/works">my recent works</Link> and <Link history={ history } to="/contact">drop me a line</Link>!
                </p>
              </div>
            </div>
        
            <div className="md-1-2 xl-1-3">
              {
                skills.map((skill, key) => (
                  <div key={ key }>
                    <h2 ref={ el => this.$skills[key].heading = el }><span>{ skill.group }</span></h2>
                    <ul className="skills">
                      {
                        skill.list.map((item, key2) => (
                          <li key={ key2 } ref={ el => this.$skills[key].list[key2].wrap = el }>
                            <span>{ item.name }</span>
                            <i className="fa fa-star-o" aria-hidden="true"></i>
                            <div>
                              <div style={{ width: `${ item.level }%` }}>
                                <div ref={ el => this.$skills[key].list[key2].gauge = el } />
                              </div>
                            </div>
                            <i className="fa fa-star" aria-hidden="true"></i>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About;
