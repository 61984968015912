import React, { Component } from 'react';
import classNames from 'classnames';
import isMobile from 'ismobilejs';
import { TweenMax, Power2 } from 'gsap';

import Link from 'components/Link';
import HoverWobble from 'components/HoverWobble';

import Content from './elements/Content';

import { MOBILE_BREAKPOINT } from 'constants.js';

import './Project.scss';

class Project extends Component {
  constructor(props) {
      super(props);

      const { data, match } = props;

      this.state = {
        currentSection: 0,
        project: data.find(project => (project.slug === match.params.project)),
      };

      this.$sections = [];
      this.wheelPause = false;

      this.isMobile = isMobile().any || props.viewport.width < MOBILE_BREAKPOINT;
  }

  componentDidMount = () => {
    const { updateRoute, viewport } = this.props;

    updateRoute('/project');

    this.$sections.forEach((section, i) => {
      TweenMax.set(section, { y: viewport.height * i });
    })

    this.animateIn();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { transitionActive, viewport } = this.props;
    const { currentSection } = this.state;

    if (!prevProps.transitionActive && transitionActive) this.animateOut();

    if (prevState.currentSection !== currentSection) {
      this.$sections.forEach((section, i) => {
        TweenMax.to(section, 0.6, {
          y: viewport.height * (i - currentSection),
          ease: Power2.easeInOut,
        });
      })
    }

    if (!prevProps.viewport !== viewport) this.isMobile = isMobile().any || viewport.width < MOBILE_BREAKPOINT;
  }

  animateIn = () => {
    TweenMax.set(this.$prompt, { y: 50, autoAlpha: 0 });
    for (let i = 1; i < this.$sections.length; i ++)
      TweenMax.set(this.$sections[i], { y: 25, autoAlpha: 0 });

    const complete = () => {
      for (let i = 1; i < this.$sections.length; i ++)
        TweenMax.to(this.$sections[i], 0.25, { y: 0, autoAlpha: 1, ease: Power2.easeOut });

      TweenMax.to(this.$prompt, 0.65, { y: 0, autoAlpha: 1, ease: Power2.easeIn, onComplete: () => {
        this.$prompt && this.$prompt.classList.add('in');
      } });
    }

    if (this.props.location.state.prevPath !== '/works' || this.isMobile) {
      TweenMax.set(this.$thumbWrap, { x: -this.$thumbWrap.offsetWidth });
      TweenMax.set(this.$thumb, { x: this.$thumbWrap.offsetWidth });
      TweenMax.set(this.$title, { x: 100, autoAlpha: 0 });

      TweenMax.to(this.$thumbWrap, 0.8, { x: 0, ease: Power2.easeIn, });
      TweenMax.to(this.$thumb, 0.8, { x: 0, ease: Power2.easeIn, onComplete: () => {
        TweenMax.to(this.$title, 0.5, { x: 0, autoAlpha: 1, ease: Power2.easeOut, });
        complete();
      } });
    } else complete();
  }

  animateOut = () => {
    if (this.state.currentSection !== 0) TweenMax.set(this.$thumbWrap, { autoAlpha: 0 });
    this.setState({ currentSection: 0 }, () => {
      this.$prompt && this.$prompt.classList.remove('in');
      TweenMax.to(this.$prompt, 0.65, { y: 50, autoAlpha: 0, ease: Power2.easeIn, });

      for (let i = this.$sections.length - 1; i > 0; i --)
        TweenMax.to(this.$sections[i], 0.25, { y: 25, autoAlpha: 0, ease: Power2.easeOut });

      TweenMax.to(this.$title, 0.5, { x: 100, autoAlpha: 0, ease: Power2.easeOut, });
      TweenMax.to(this.$thumbWrap, 0.8, { x: -this.$thumbWrap.offsetWidth, ease: Power2.easeInOut, });
      TweenMax.to(this.$thumb, 0.8, { x: this.$thumbWrap.offsetWidth, ease: Power2.easeInOut, onComplete: () => {
        this.props.activateTransition(false)
      } });
    })
  }

  handleWheel = e => {
    if (this.wheelPause || e.deltaY === 0 || e.deltaY === -0 || this.isMobile) return;
    this.wheelPause = true;
    const { currentSection } = this.state;

    if (e.deltaY > 0 && currentSection < this.$sections.length - 1)
      this.setState({ currentSection: currentSection + 1 });
    if (e.deltaY < 0 && currentSection > 0)
      this.setState({ currentSection: currentSection - 1 });

    setTimeout(() => this.wheelPause = false, 500);
  }

  handleSectionSwitch = () => {
    const { currentSection } = this.state;
    this.setState({
      currentSection: currentSection === this.$sections.length -1 ? 0 : currentSection + 1
    });
  }

  render = () => {
    const { history, viewport } = this.props;
    const { currentSection, project } = this.state;
    const classes = classNames({
      'Project': true,
      'is-mobile': this.isMobile,
    })
    const sectionClasses = (section, key) => (classNames({
      [section]: true,
      'active': key === currentSection
    }))
  
    return (
      <div className={ classes } ref={ el => this.$node = el } onWheel={ this.handleWheel }>
        <section className={ sectionClasses('intro', 0) } ref={ el => this.$sections[0] = el }>
          <div ref={ el => this.$thumbWrap = el } className="thumbnail-wrap">
            <div className="thumbnail" ref={ el => this.$thumb = el }>
              <div style={{ backgroundImage: `url('${ project.image }')` }} />
            </div>
          </div>
          <h2 ref={ el => this.$title = el } style={{ right: `${ viewport.width * 0.1 }px`, bottom: `50vh` }}>
            { project.name }
          </h2>

        </section>
        <section className={ sectionClasses('content', 1) }  ref={ el => this.$sections[1] = el }>
          <Content project={ project } />
        </section>

        <h2 className={ currentSection !== 0 ? 'shown' : 'hidden' }>
          { project.name }
          <Link history={ history } to="/works"><HoverWobble><div className="arrow" /></HoverWobble></Link>
        </h2>

        <div className="scroll-prompt" ref={ el => this.$prompt = el } onClick={ this.handleSectionSwitch }>
          <div className="prompt"><HoverWobble>SCROLL</HoverWobble></div>
          <div className="section-indicator">
            <div style={{ transform: `scaleY(${ (1 + currentSection) / 2 })` }} />
          </div>
        </div>
      </div>
    )
  }
}

export default Project;
