import { connect } from 'react-redux';
import Preloader from './Preloader';

import { completePreload, updateData } from 'redux/actions';

const mapStateToProps = state => ({
  preloadCompleted: state.app.preloadCompleted,
});

const mapDispatchToProps = {
  completePreload,
  updateData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preloader);
