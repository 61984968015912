import React, { Component } from 'react';
import classNames from 'classnames';
import isMobile from 'ismobilejs';
import Flickity from 'react-flickity-component';
import gsap, { TweenMax, Power0, Power1, Power2 } from 'gsap';

import Slide from './elements/Slide';

import { MOBILE_BREAKPOINT } from 'constants.js';

import './Works.scss';

class Works extends Component {
  constructor(props) {
      super(props);

      this.state = {
        transitioning: true,
        toProject: false,
      }

      this.$slides = [];
      this.$slidesInner = [];
      this.$slidesBG = [];
      this.$slidesTitle = [];

      this.wheelPause = false;

      this.isMobile = isMobile().any || props.viewport.width < MOBILE_BREAKPOINT;
  }

  componentDidMount = () => {
    const { updateRoute, currentSlide, updateCurrentSlide } = this.props;

    updateRoute('/works');
    TweenMax.set(this.$node, { autoAlpha: 0 });

    this.$flkty.on('ready', () => {
      this.$flkty.selectCell(currentSlide, true, true);

      const main = this.$slidesInner[currentSlide];

      TweenMax.set(this.$node, { autoAlpha: 1 });
      TweenMax.set(main, { x: -main.offsetWidth });
      TweenMax.set(this.$slidesBG[currentSlide], { x: main.offsetWidth });
      TweenMax.set(this.getPrevSlide(), { x: '-100%' });
      TweenMax.set(this.getNextSlide(), { x: '100%' });
      this.animateIn();
    });

    this.$flkty.on('change', i => {
      updateCurrentSlide(i);
      this.autoPlay();
    })
  }

  componentDidUpdate = (prevProps) => {
    const { currentSlide, transitionActive, viewport } = this.props;

    if (prevProps.currentSlide === currentSlide) this.$flkty.selectCell(currentSlide, true, true);
    if (!prevProps.transitionActive && transitionActive) this.animateOut();
    if (!prevProps.viewport !== viewport) this.isMobile = isMobile().any || viewport.width < MOBILE_BREAKPOINT;
  }

  animateIn = () => {
    const { currentSlide } = this.props;

    TweenMax.to(this.$slidesInner[currentSlide], 0.8, { x: 0, ease: Power2.easeIn, });
    TweenMax.to(this.$slidesBG[currentSlide], 0.8, { x: 0, ease: Power2.easeIn,
      onComplete: () => {
        TweenMax.to(this.getPrevSlide(), 0.5, { x: '0%', ease: Power2.easeOut, });
        TweenMax.to(this.getNextSlide(), 0.5, { x: '0%', ease: Power2.easeOut, });
        this.autoPlay();
        this.setState({ transitioning: false });
      }
    });
  }

  animateOut = (toProject = false) => {
    this.setState({ transitioning: true, toProject });

    const { viewport, data, currentSlide, location } = this.props;

    TweenMax.to(this.getPrevSlide(), 0.25, { x: '-100%', autoAlpha: 0, ease: Power1.easeIn, })
    TweenMax.to(this.getNextSlide(), 0.25, { x: '100%', autoAlpha: 0, ease: Power1.easeIn, onComplete: () => {
      const main = this.$slidesInner[currentSlide];
      const onComplete = () => {
        if (toProject) this.props.history.push({
          pathname: `/project/${ data[currentSlide].slug }`,
          state: { prevPath: location.pathname }, 
        });
        else this.props.activateTransition(false)
      }

      if (toProject && !this.isMobile) {
        const bcr = main.getBoundingClientRect();
        const titleBcr = this.$slidesTitle[currentSlide].getBoundingClientRect();

        TweenMax.to(this.$slidesTitle[currentSlide], .25, {
          x: viewport.width * 0.9 - titleBcr.right,
          y: viewport.height / 2 - titleBcr.bottom + titleBcr.height / 2,
          ease: Power2.easeInOut,
        });

        TweenMax.to(main, 0.85, {
          x: -bcr.x,
          y: viewport.height - bcr.bottom - viewport.height * 0.15,
          ease: Power2.easeOut,
          onComplete,
        });
      } else {
        TweenMax.to(main, .5, { x: -main.offsetWidth, ease: Power2.easeOut, });
        TweenMax.to(this.$slidesBG[currentSlide], .5, {
          x: main.offsetWidth,
          ease: Power2.easeOut,
          onComplete,
        });
      }
    } })
  }

  getPrevSlide = () => {
    const { currentSlide } = this.props;
    return currentSlide > 0 ? this.$slides[currentSlide - 1] : this.$slides[this.$slides.length - 1];
  }

  getNextSlide = () => {
    const { currentSlide } = this.props;
    return currentSlide < this.$slides.length - 1 ? this.$slides[currentSlide + 1] : this.$slides[0];
  }

  progressTimeline = gsap.timeline();

  autoPlay = () => {
    this.progressTimeline.restart();
    this.progressTimeline.set(this.$progress, { scaleX: 0 });
    this.progressTimeline.to(this.$progress, {
      duration: 10,
      scaleX: 1,
      ease: Power0.easeNone,
      onComplete: () => this.switchSlide(this.getNextSlide()),
    });
  }

  switchSlide = i => {
    this.$flkty.selectCell(i);
  }

  handleClick = i => {
    const { currentSlide } = this.props;
    if (currentSlide === i) this.animateOut(true);
  }

  handleWheel = e => {
    if (this.wheelPause || e.deltaY === 0 || e.deltaY === -0 || this.state.transitioning) return;
    this.wheelPause = true;
    if (e.deltaY > 0) this.$flkty.selectCell(this.getNextSlide());
    if (e.deltaY < 0) this.$flkty.selectCell(this.getPrevSlide());
    setTimeout(() => this.wheelPause = false, 750);
  }

  render = () => {
    const { data, currentSlide } = this.props;
    const { transitioning, toProject } = this.state;
    const classes = classNames({
      'Works': true
    })
    const flickityOptions = {
      friction: 0.4,
      wrapAround: true,
      prevNextButtons: false,
    }
  
    return (
      <div className={ classes } ref={ el => this.$node = el } onWheel={ this.handleWheel }>
        <Flickity
          flickityRef={ el => this.$flkty = el }
          className={'carousel'}
          elementType={ 'div' }
          options={ flickityOptions }
        >
          {
            data.map((work, key) => (
              <div key={ key } ref={ el => this.$slides[key] = el } className="slide">
                <div ref={ el => this.$slidesInner[key] = el }>
                  <Slide
                    current={ key === currentSlide }
                    data={ work }
                    initThumbnailRef={ el => this.$slidesBG[key] = el }
                    onClick={ () => this.handleClick(key) }
                    setCurrent={ () => this.switchSlide(key) }
                    transitioning={ transitioning }
                    transitioningToProject={ toProject && !this.isMobile }
                  />
                </div>
                <div
                  ref={ el => this.$slidesTitle[key] = el }
                  className={ `info${ transitioning && (!toProject || this.isMobile) ? '' : ' in' }${ toProject && !this.isMobile ? ' anim-out' : ''}` }
                >
                  <h2>{ work.name }</h2>
                  <span><h2>{ work.name }</h2></span>
                </div>
              </div>
            ))
          }
        </Flickity>
        <div className={ `counter${ transitioning ? '' : ' in' }`}>
          <div className="progress"><div ref={ el => this.$progress = el } /></div>
          <span>{ `${ currentSlide + 1 } / ${ data.length }` }</span>
        </div>
      </div>
    )
  }
}

export default Works;
