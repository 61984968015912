import { connect } from 'react-redux';
import About from './About';

import { updateRoute, activateTransition } from 'redux/actions';

const mapStateToProps = state => ({
  about: state.app.data.about,
  skills: state.app.data.skills,
  transitionActive: state.app.transitionActive,
});

const mapDispatchToProps = {
  updateRoute,
  activateTransition,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
