import React, { Component } from 'react';
import classNames from 'classnames';

import './Loader.scss';

class Loader extends Component {
  constructor(props) {
      super(props);

      this.state = {
        
      };
  }

  render = () => {
    const classes = classNames({
      'Loader': true,
      'hidden': this.props.hidden,
    })
  
    return (
      <div className={ classes } ref={ el => this.$node = el }>
        <div/><div/><div/><div/><div/>
      </div>
    )
  }
}

export default Loader;
