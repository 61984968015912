import { combineReducers } from 'redux';

const initStates = {
  preloadCompleted: false,
  data: null,
  viewport: { width: 0, height: 0 },
  route: '/',
  transitionActive: false,
  currentSlide: 0,
}

const app = (state = initStates, action) => {
  switch (action.type) {

  case 'COMPLETE_PRELOAD':
    return {
      ...state,
      preloadCompleted: action.preloadCompleted,
    };

  case 'UPDATE_DATA':
    return {
      ...state,
      data: action.data,
    };

  case 'UPDATE_VIEWPORT':
    return {
      ...state,
      viewport: action.viewport,
    };

  case 'UPDATE_ROUTE':
    return {
      ...state,
      route: action.route,
    };

  case 'ACTIVATE_TRANSITION':
    return {
      ...state,
      transitionActive: action.state,
    };

  case 'UPDATE_CURRENT_SLIDE':
    return {
      ...state,
      currentSlide: action.slide,
    };

  default:
    return state;
  }
}

export const reducers = combineReducers({
  app,
});
